<mat-dialog-content>
  <h2 class="mb-5" mat-dialog-title>{{ getDialogTitle() }}</h2>
  <form [formGroup]="form" autocomplete="off">
    <!-- Tipo de Mensagem -->
    <mat-form-field class="col-6 mb-2">
      <mat-label>Tipo de Mensagem</mat-label>
      <mat-select formControlName="type" required>
        <mat-option *ngFor="let option of typeOptions" [value]="option.id">{{ option.label }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Horário -->
    <mat-form-field class="col-6" *ngIf="showTimeSelector()">
      <mat-label>Horário</mat-label>
      <mat-select formControlName="time" required>
        <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Dias após fechamento -->
    <mat-form-field class="col-3 mb-2" *ngIf="isPosVenda()">
      <mat-label>Os fechada a</mat-label>
      <input matInput type="number" formControlName="osDay" required>
    </mat-form-field>

    <!-- Mensagem -->
    
    <mat-form-field class="mat-form-field col-12 d-flex flex-column mb-2">
      <mat-label>Mensagem</mat-label>
      <input matInput maxlength="300" class="mt-2" formControlName="message" required>
      <button mat-icon-button class="emoji-button position-absolute" (click)="toggleEmojiPicker(); $event.stopPropagation();">
        😊 <!-- Ícone do botão do emoji, você pode usar outro emoji aqui -->
      </button>
      
    </mat-form-field>

    <div class="ajust-emoji" *ngIf="showEmojiPicker">
      <emoji-mart (emojiClick)="addEmoji($event)"></emoji-mart>

    </div>

    

    <!-- Legendas de Ajuda -->
    <div *ngIf="!showTimeSelector()" class="help-text">
      <p>Utilize as seguintes palavras-chave: {{'{'}}{{'{'}} os {{'}}'}} = número OS, {{'{'}}{{'{'}} plate {{'}}'}} = número da placa, {{'{'}}{{'{'}} company {{'}}'}} = nome empresa, {{'{'}}{{'{'}} client {{'}}'}} = Nome Cliente, {{'{'}}{{'{'}} budget {{'}}'}} = Número Orçamento, {{'{'}}{{'{'}} consultor {{'}}'}} = Nome do.</p>
      <p>Exemplo de mensagem: Uma nova OS {{'{'}}{{'{'}} os {{'}}'}} para a placa {{'{'}}{{'{'}} plate {{'}}'}} em {{'{'}}{{'{'}} company {{'}}'}} acaba de ser fechada.</p>
    </div>
    

    <!-- Botões de Ação -->
    <mat-dialog-actions>
      <button mat-raised-button color="warn" [mat-dialog-close]="false">Cancelar</button>
      <button mat-raised-button color="primary" (click)="save()">Salvar</button>
      <button mat-raised-button color="accent" (click)="deleteMessage()" *ngIf="form.get('_id').value && (profile | canAccess: 'RECORDS.controlMessages.delete')">Excluir</button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>


    <!-- Container para o Seletor de Emojis -->
    
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UnsubscribeAll } from '../../../class/unsubscribe-all';
import { Commission } from '../../../interface/commission';
import { PartGroup } from '../../../interface/part-group';
import { User } from '../../../interface/user';
import { GroupService } from '../../../service/group.service';
import { LayoutService } from '../../../service/layout.service';
import { SnackbarService } from '../../../service/snackbar.service';
import { UserService } from '../../../service/user.service';
import { GROUP_KIND } from '../../../shared/lists/group-kind';
import { PART_TYPES } from '../../../shared/lists/part-types';

@Component({
  selector: 'app-group-by-expanse-type-edit',
  templateUrl: './group-by-expanse-type-edit.component.html',
  styleUrl: './group-by-expanse-type-edit.component.scss'
})
export class GroupByExpanseTypeEditComponent extends UnsubscribeAll implements OnInit, OnDestroy {
  PART_TYPES = PART_TYPES;
  GROUP_KIND = GROUP_KIND;
  form: FormGroup;
  group: PartGroup;

  //Commission
  //commissionDefault?: number;
  commissionUser: string; // id person (user)
  commissionValue: number;
  users: User[] = [];
  u2: MatTableDataSource<Commission> = new MatTableDataSource([]);
  // u2: Commission[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      group: PartGroup
    },
    private dialogRef: MatDialogRef<GroupByExpanseTypeEditComponent>,
    private formBuilder: FormBuilder,
    private groupService: GroupService,
    private layout: LayoutService,
    private snackbar: SnackbarService,

    //Commission
    private _userService: UserService

  ) {
    super();
    if (data && data.group) {
      this.group = data.group
    }
  }

  async ngOnInit() {
    await this.getUsers();
    this.buildForm();
    if(this.group.groupKind == 1 || this.group.groupKind == 2){
      this.GROUP_KIND = {part: 1, labor: 2, expense: null}
    } else {
      this.GROUP_KIND = {part: null, labor: null, expense: 3}
    }
    // disable or enable the part type on select group kind
    this.subs.push(this.form.get('groupKind')?.valueChanges.subscribe(newKind => {
      const typeControl = this.form.get('type');
      if (newKind === GROUP_KIND.part) {
        typeControl.enable();
        if(this.group?.type){
          typeControl.setValue(this.group.type);
        }
      } else {
        typeControl.reset();
        typeControl.disable();
      }
    }));
  }

  override ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  async getUsers() {
    this.users = await this._userService.getAll();
  }

  buildForm() {
    if (this.group && this.group.commission && this.group.commission.length) {
      this.group.commission.forEach(c => {
        c.user = this.users.find(user => user.id === c.person)
      });
    }

    this.form = this.formBuilder.group({
      _id: this.group ? this.group._id : "",
      // code: [this.group ? this.group.code :  "", [Validators.required, this.unique(this.groupService.groups)]],
      description: [this.group ? this.group.description : "", Validators.required],
      groupKind: [this.group ? this.group.groupKind : GROUP_KIND.expense, Validators.required],
    })
    //console.log(this.group)
    this.u2.data = this.group ? this.group.commission : [];
  }

  async onSubmit() {
    let newGroup = this.form.getRawValue();
    const isUpdate = Boolean(newGroup._id);
    try {
      this.layout.loader = true;
      if (isUpdate) {
        await this.groupService.update(newGroup)
      } else {
        const id = await this.groupService.register(newGroup);
        newGroup['_id'] = id;
      }
      this.snackbar.show(isUpdate ? "GROUP.UPDATED" : "GROUP.ADDED", true)
      this.dialogRef.close(newGroup);
    } catch (error) {
      this.snackbar.show(isUpdate ? "GROUP.NOT_UPDATED" : "GROUP.NOT_ADDED", false)
      console.error(error)
    } finally {
      this.layout.loader = false;
    }
  }

  close() {
    this.dialogRef.close(null);
  }

  //
  addCommission() {

    let commission = this.form.get('commission').value;

    const updateCommission = {
      user: this.commissionUser,
      value: this.commissionValue
    }

    /*const findCommission= this.u2.data.find(function(item) {
      return item.user == this.commissionUser
    });*/
    let findCommission = commission.find(com => com.user === this.commissionUser)
    //const findCommission = this.u2.data.filter(com => com.user === this.commissionUser)
    //if(this.u2.data.some(com => com.user === this.commissionUser)){
    if (findCommission) {
      findCommission.value = this.commissionValue;

    } else {
      commission.push({
        user: this.commissionUser,
        value: this.commissionValue
      });
    }

    this.u2.data = commission;

    this.form.get('commission').setValue(commission);
    this.form.updateValueAndValidity();

    this.commissionUser = "";
    this.commissionValue = undefined;


  }


  removeCommission(comm) {
    let commission = this.form.get('commission').value;
    this.u2.data.splice(this.u2.data.indexOf(comm), 1);
    //commission.splice(index, 1);
    this.u2.data = this.u2.data;
    this.form.get('commission').setValue(this.u2.data);
    this.form.updateValueAndValidity();
  }


}
